import React, { useCallback, useState, useEffect, PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import JobService, { JobInterface } from "dataProvider/Reference/Job";

interface Options {
    value: string | number;
    label: string;
}

export interface getReferenceReturn {
    job: JobInterface;
}

export interface ReferencesProviderInterface {
    all<T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]>;
    selectList<T extends keyof getReferenceReturn>(type: T): Array<Options>;
    get<T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined;
    refreshData<T extends keyof getReferenceReturn>(type?: T): void;
}

type BaseReferencesStateInterface = {
    [Key in keyof getReferenceReturn]: Array<getReferenceReturn[Key]>;
};

const ReferenceContext = React.createContext<ReferencesProviderInterface>({
    all: () => [],
    get: () => undefined,
    selectList: () => [],
    refreshData: () => null,
});

const Consumer = ReferenceContext.Consumer;

const Provider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { locale } = useIntl();

    const [references, setReferences] = useState<BaseReferencesStateInterface>({
        job: [],
    });

    const fetchReferences = useCallback(async (languageCode: string, type?: string) => {
        switch (type) {
            case "job":
                const jobs = await JobService.all(languageCode);
                setReferences(Object.assign({}, references, { job: jobs }));
                break;
            default:
                let newReferences: BaseReferencesStateInterface = {} as BaseReferencesStateInterface;

                Promise.all([JobService.all(languageCode).then((res) => (newReferences.job = res))]);

                setReferences(newReferences);
                break;
        }
        // eslint-disable-next-line
    }, []);

    const refreshReference = useCallback(
        <T extends keyof getReferenceReturn>(type?: T): void => {
            fetchReferences(locale, type);
        },
        [fetchReferences, locale]
    );

    const getReference = useCallback(
        <T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined => {
            return references[type].find((value) => {
                return code && value.code.toString() === code.toString();
            });
        },
        [references]
    );

    const allReferences = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]> => {
            return references[type];
        },
        [references]
    );

    const formatForSelect = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<Options> => {
            return references[type]
                .filter((value: any) => !value.deleted_at)
                .map((value: any) => {
                    return {
                        label: value.value !== undefined ? value.value : value.code,
                        value: value.code,
                    };
                });
        },
        [references]
    );

    // @TODO LOADER : usePromise ?
    useEffect(() => {
        fetchReferences(locale);
    }, [fetchReferences, locale]);

    return (
        <ReferenceContext.Provider
            value={{
                get: getReference,
                all: allReferences,
                selectList: formatForSelect,
                refreshData: refreshReference,
            }}
        >
            {children}
        </ReferenceContext.Provider>
    );
};

export { Provider, Consumer, ReferenceContext as Context };
