import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import TopbarMenuItem, { ITopbarMenuItem } from "./TopbarMenuItem";

interface Props {
    label: string;
    subItems: ITopbarMenuItem[];
}

const TopbarSubMenuItem: React.FC<Props> = ({ label, subItems }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const subMenuRef = useRef(null);
    const location = useLocation();
    const subItemPaths = useMemo(() => {
        const paths: string[] = [];

        subItems.forEach((subItem) => paths.push(subItem.to));

        return paths;
    }, [subItems]);

    let itemClass = "topbar-menu__link";
    let submenuClass = "topbar-submenu";

    if (isOpen) {
        submenuClass += " topbar-submenu--open";
    }

    if (isActive) {
        itemClass += " active";
    }

    useEffect(() => {
        let submenuIsActive = false;

        subItemPaths.forEach((path) => {
            if (submenuIsActive) {
                return;
            }

            if (location.pathname.startsWith(path)) {
                submenuIsActive = true;
            }
        });

        return setIsActive(submenuIsActive);
    }, [subItemPaths, isActive, location]);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (subMenuRef.current === e.target) {
                return;
            }

            return setIsOpen(false);
        };

        if (isOpen) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [isOpen]);

    return (
        <li className="topbar-menu__item">
            <span className={itemClass} onClick={() => setIsOpen(!isOpen)} ref={subMenuRef}>
                {label}
                <ul className={submenuClass}>
                    {subItems.map((subItem, i) => (
                        <TopbarMenuItem
                            key={i.toString()}
                            label={subItem.label}
                            to={subItem.to}
                            classPrefix="topbar-submenu"
                        />
                    ))}
                </ul>
            </span>
        </li>
    );
};

export default TopbarSubMenuItem;
