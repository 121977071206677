import { useMemo } from "react";
import qs, { ParsedQuery } from "query-string";

interface IUseQueryResult {
    values: ParsedQuery;
    all: () => ParsedQuery;
    get: (name: string) => string | Array<string | null> | null;
    set: (name: string, value: string) => void;
    setAll: (data: HashMap<string>) => void;
    reset: () => void;
}

const setQueryStringWithoutPageReload = (qsValue: string): void => {
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;
    window.history.pushState({ path: newurl }, "", newurl);
    window.dispatchEvent(new HashChangeEvent("hashchange"));
};

const setQueryStringValue = (key: string, value: string, queryString = window.location.search): void => {
    const values = qs.parse(queryString);
    const newQsValue = qs.stringify({ ...values, [key]: value });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const setQueryStringValues = (values: HashMap<string>, queryString = window.location.search): void => {
    const newQsValue = qs.stringify({ ...values });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const useQueryString = (queryString = window.location.search): IUseQueryResult => {
    const values = useMemo(() => qs.parse(queryString), [queryString]);
    return {
        values,
        all: () => values,
        get: (name: string) => values[name] || null,
        set: setQueryStringValue,
        setAll: setQueryStringValues,
        reset: () => setQueryStringWithoutPageReload(""),
    };
};

export default useQueryString;
