import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface JobInterface extends ReferenceInterface {}

class Job {
    all(languageCode: string) {
        return parseResponse<Array<JobInterface>>(fetch.get(`reference/job/${languageCode}`));
    }
}

const dataFetcher = new Job();

export default dataFetcher;
