import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as ReferenceProvider } from "context/Reference";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import FlashMessages from "components/App/FlashMessages";
import ErrorBoundary from "components/App/ErrorBoundary";
import Loader from "components/App/Loader";
import ModuleRouter from "./ModuleRouter";

import "assets/sass/main.scss";
import "assets/icons";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <IntlProvider>
                <ErrorBoundary>
                    <ReferenceProvider>
                        <FlashMessagesProvider FlashComponent={FlashMessages}>
                            <BrowserRouter basename={process.env.PUBLIC_URL}>
                                <Suspense fallback={<Loader />}>
                                    <ModuleRouter />
                                </Suspense>
                            </BrowserRouter>
                        </FlashMessagesProvider>
                    </ReferenceProvider>
                </ErrorBoundary>
            </IntlProvider>
        </AuthProvider>
    );
};

export default App;
