import fetch, { parseResponse } from "./fetch";

class Language {
    list(languageCode: string) {
        return parseResponse<HashMap<string>>(fetch.get(`language/all/${languageCode}`));
    }
}

const dataFetcher = new Language();

export default dataFetcher;
