import axios, { AxiosResponse } from "axios";
import FileInterface, { FileResponseInterface } from "./FileInterface";
import base64toBlob from "helpers/file/base64ToBlob";

const SESSION_EXPIRATION_DATE_KEY: string = "session_expiration_date";
const SESSION_LIFETIME: number = Number(process.env.REACT_APP_SESSION_LIFETIME);

function refreshExpirationDate() {
    const expirationDate = new Date();
    expirationDate.setTime(new Date().getTime() + 60000 * SESSION_LIFETIME);
    localStorage.setItem(SESSION_EXPIRATION_DATE_KEY, expirationDate.toISOString());
}

function clearSession() {
    localStorage.removeItem(SESSION_EXPIRATION_DATE_KEY);
}

function getSessionExpirationDate(): Date | null {
    const data = localStorage.getItem(SESSION_EXPIRATION_DATE_KEY);
    if (data) {
        return new Date(data);
    }
    return null;
}

function sessionIsExpired(): boolean {
    const expirationDate = getSessionExpirationDate();
    if (expirationDate) {
        return expirationDate < new Date();
    }
    return true;
}

const API_URL = process.env.REACT_APP_SERVER_URL;

class fetch {
    sessionIsExpired() {
        return sessionIsExpired();
    }

    clearSession() {
        return clearSession();
    }

    get<T = any>(url: string, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<T>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<T>>> = axios.get(
            API_URL + url,
            Object.assign({}, config, { cancelToken: CancelToken.token })
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<T>>;
    }

    delete<T = any>(url: string, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<T>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<T>>> = axios.delete(
            API_URL + url,
            Object.assign({}, config, { cancelToken: CancelToken.token })
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<T>>;
    }

    post<T = any>(url: string, data?: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<T>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<T>>> = axios.post(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.token })
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<T>>;
    }

    put<T = any>(url: string, data?: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<T>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<T>>> = axios.put(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.token })
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<T>>;
    }

    patch<T = any>(url: string, data: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<T>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<T>>> = axios.patch(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.token })
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<T>>;
    }
}

const query_debug = function (response: AxiosResponse<any>) {
    if (process.env.NODE_ENV === "development") {
        if (response.data && response.data.debug) {
            const debug = response.data.debug;
            const config = response.config;
            if (debug.warnings && debug.warnings.length) {
                console.groupCollapsed(
                    `%c Request warning : ${config.method} ${config.url}`,
                    "color: #856404; background-color: #fff3cd; border-color: #ffeeba;"
                );
                console.log(debug.warnings);
                console.groupEnd();
            }
            if (debug.custom_data) {
                console.groupCollapsed(
                    `%c Request custom data : ${config.method} ${config.url}`,
                    "color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb;"
                );
                for (let index = 0; index < debug.custom_data.length; index++) {
                    const element = debug.custom_data[index];
                    console.log(element);
                }
                console.groupEnd();
            }
        }
    }
};

axios.defaults.withCredentials = true;

// Axios Session interceptor
axios.interceptors.response.use(
    function (response: AxiosResponse<any>) {
        refreshExpirationDate();
        query_debug(response);
        return response;
    },
    function (error: any) {
        if (error.response) {
            query_debug(error.response);
            if (error.response.status === 401) {
                clearSession();
            }
        }
        // Do something with response error
        return Promise.reject(error);
    }
);

const singleton = new fetch();
export default singleton;

function wrapPromiseParse<T, R>(
    fetcher: CancellablePromise<AxiosResponse<T>>,
    callback: (response: AxiosResponse<T>) => R
): CancellablePromise<R> {
    const promise = fetcher.then((response) => {
        return callback(response);
    }) as CancellablePromise<R>;

    promise.cancel = fetcher.cancel;

    return promise;
}

export function parseResponse<T>(fetcher: CancellablePromise<AxiosResponse<{ data: T }>>): CancellablePromise<T> {
    return wrapPromiseParse(fetcher, (response) => {
        return response.data.data;
    });
}

export function parseFileResponse(
    fetcher: CancellablePromise<AxiosResponse<{ data: FileResponseInterface }>>
): CancellablePromise<FileInterface> {
    return wrapPromiseParse(fetcher, (response) => {
        const data = { ...response.data.data };
        return Object.assign({}, data, { content: base64toBlob(data.content, data.type) });
    });
}

interface ListResponse<T> {
    data: Array<T>;
    pager: any;
    filters: any;
}

export function parseListResponse<T>(fetcher: CancellablePromise<AxiosResponse<ListResponse<T>>>) {
    return wrapPromiseParse(fetcher, (response) => {
        const payload = response.data;
        return Object.assign([...payload.data], {
            active_filters: Object.assign({}, payload.filters),
            pager: payload.pager,
        });
    });
}
