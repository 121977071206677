import React from "react";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string;
    withLoader?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    children,
    withLoader,
    className = "btn",
    type = "button",
    color = "default",
    ...props
}) => {
    return (
        <button type={type} className={`${className} btn--${color}`} {...props}>
            {children}
        </button>
    );
};
export default Button;
