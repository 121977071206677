import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";
import LogoText from "assets/images/logo-rallye-text.svg";
import TopbarMenuItem from "./TopbarMenuItem";
import TopbarSubMenuItem from "./TopbarSubMenuItem";

const Topbar: React.FC = () => {
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);

    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const toggleMenu = () => setOpenMenu(!openMenu);

    const memberMenu = () => {
        const menu = [];

        if (auth.isAllowed("admin")) {
            menu.push({
                label: intl.formatMessage("generic.menu.members"),
                to: "/admin/families",
            });
            menu.push({
                label: intl.formatMessage("generic.menu.members.details"),
                to: "/admin/families/details",
            });
            menu.push({
                label: intl.formatMessage("generic.menu.registration_link"),
                to: "/admin/registration",
            });
        }

        if (auth.isAllowed("teacher")) {
            menu.push({
                label: intl.formatMessage("generic.menu.teachers"),
                to: "/teacher",
            });
        }

        return menu;
    };

    const organisationMenu = () => {
        const menu = [];

        if (auth.isAllowed("admin")) {
            menu.push({
                label: intl.formatMessage("generic.menu.organisations.rallye"),
                to: "/admin/rallies",
            });
            menu.push({
                label: intl.formatMessage("generic.menu.organisations.avoid_dates"),
                to: "/admin/avoid_dates",
            });
        }

        if (auth.isAllowed("export")) {
            menu.push({
                label: intl.formatMessage("generic.menu.organisations.export"),
                to: "/export",
            });
        }

        return menu;
    };

    return (
        <header className={`topbar ${auth.isLoggedIn ? " topbar--connected" : ""} ${openMenu ? " topbar--open" : ""}`}>
            <div className="container">
                <Link to="/" className="topbar__logo">
                    <img src={LogoText} alt="Rallye Clés en main" />
                    <span className="visuallyhidden">Vers la page d'accueil Rallye Clés en Main</span>
                </Link>

                <div className="topbar__content">
                    {auth.isLoggedIn && (
                        <nav className="topbar__nav">
                            <ul className="topbar-menu">
                                {(auth.isAllowed("admin") || auth.isAllowed("teacher")) && (
                                    <TopbarSubMenuItem
                                        label={intl.formatMessage("generic.menu.members")}
                                        subItems={memberMenu()}
                                    />
                                )}

                                {auth.isAllowed("profile") && (
                                    <TopbarMenuItem label={intl.formatMessage("generic.menu.profile")} to="/profile" />
                                )}

                                {(auth.isAllowed("admin") || auth.isAllowed("export")) && (
                                    <TopbarSubMenuItem
                                        label={intl.formatMessage("generic.menu.organisations")}
                                        subItems={organisationMenu()}
                                    />
                                )}

                                {auth.isAllowed("planning.activity.view") && (
                                    <TopbarSubMenuItem
                                        label={intl.formatMessage("generic.menu.planification")}
                                        subItems={[
                                            {
                                                label: intl.formatMessage("generic.menu.planification.activity"),
                                                to: "planning/activities",
                                            },
                                        ]}
                                    />
                                )}

                                {auth.isAllowed("admin") && (
                                    <TopbarMenuItem
                                        label={intl.formatMessage("generic.menu.settings")}
                                        to="/admin/settings"
                                    />
                                )}
                            </ul>
                        </nav>
                    )}
                    <nav className="topbar__nav topbar__right">
                        {auth.isLoggedIn && (
                            <ul className="topbar-menu topbar-menu__user">
                                {auth.isAllowed("profile") ? (
                                    <TopbarMenuItem label={`${auth.account?.user.last_name}`} to="/profile" />
                                ) : (
                                    <li className="topbar-menu__item">
                                        <span className="topbar-menu__link">{auth.account?.user.last_name}</span>
                                    </li>
                                )}
                                <Button className="topbar__btn" onClick={auth.logout}>
                                    <span className="icon icon-Logout" aria-hidden="true">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </span>
                                    <span className="visuallyhidden tooltip">
                                        {intl.formatMessage("authentication.login.action.logout")}
                                    </span>
                                </Button>
                            </ul>
                        )}
                    </nav>
                </div>

                <Button className="topbar__btn-menu" onClick={toggleMenu}>
                    <span className="topbar__btn-bar"></span>
                    <span className="topbar__btn-bar"></span>
                    <span className="topbar__btn-bar"></span>
                </Button>
            </div>
        </header>
    );
};

export default Topbar;
