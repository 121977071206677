import { parseResponse } from "dataProvider/fetch";
import fetch, { parseListResponse } from "./fetch";
import { RallyeInterface } from "./Admin/Rallye";

class Rallye {
    all(params?: HashMap<any>) {
        return parseListResponse<RallyeInterface>(fetch.get("rallies", { params }));
    }

    one(id: string) {
        return parseResponse<RallyeInterface>(fetch.get(`rallies/${id}`));
    }

    fromFamily(params?: HashMap<any>) {
        return parseListResponse<RallyeInterface>(fetch.get(`rallies/from/family`, { params }));
    }
}

const dataFetcher = new Rallye();

export default dataFetcher;
