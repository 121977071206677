import React from "react";

interface Props {
    message?: string;
}

const Loader: React.FC<Props> = ({ message }) => {
    return (
        <div className="loader-wrapper">
            <div className="loader" />
            <div className="text-center">{message ? <p className="loader-text">{message}</p> : null}</div>
        </div>
    );
};

export default Loader;
