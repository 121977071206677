import React, { PropsWithChildren } from "react";
import fetch from "dataProvider/fetch";
import { withAuth, AuthProps } from "context/Auth";
import { withIntl, IntlProps } from "context/Intl";
import { withFlashMessages, FlashMessagesProps } from "context/FlashMessages";

type Props = AuthProps & IntlProps & FlashMessagesProps;

let logoutIntervalId: NodeJS.Timeout | null = null;

class LogoutOnExpirationComponent extends React.Component<PropsWithChildren<Props>> {
    logoutIfSessionIsExpired = () => {
        const { auth, intl, flashMessages } = this.props;
        if (auth.isLoggedIn && fetch.sessionIsExpired()) {
            auth.logout();
            flashMessages.addWarning(intl.formatMessage("generic.logout.inactivity"));
        }
    };

    componentDidMount() {
        logoutIntervalId && clearInterval(logoutIntervalId);
        this.logoutIfSessionIsExpired();
        logoutIntervalId = setInterval(this.logoutIfSessionIsExpired, 10000);
    }

    render() {
        return this.props.children;
    }
}

export default withAuth(withIntl(withFlashMessages(LogoutOnExpirationComponent)));
