import React, { useState, PropsWithChildren } from "react";

enum FlashTypes {
    success = "success",
    error = "error",
    warning = "warning",
}

export interface FlashMessage {
    id: number;
    type: FlashTypes;
    duration: number;
    text: string;
}

export interface FlashActionProviderInterface {
    addSuccess: (text: string, duration?: number) => void;
    addError: (text: string, duration?: number) => void;
    addWarning: (text: string, duration?: number) => void;
    removeFlashMessage: (flashMessageId: number) => void;
}

export interface FlashMessageStateInterface {
    messages: Array<FlashMessage>;
    actions: FlashActionProviderInterface;
}

const FlashActionsContext = React.createContext<FlashActionProviderInterface | null>(null);

const Consumer = FlashActionsContext.Consumer;

interface Props {
    FlashComponent: React.ComponentType<any>;
}

let nextFlashMessageId = 1;

const Provider: React.FC<PropsWithChildren<Props>> = ({ FlashComponent, children }) => {
    const [messages, setMessages] = useState<Array<FlashMessage>>([]);

    const removeFlashMessage = (flashMessageId: number) => {
        setMessages(messages.filter((f) => flashMessageId !== f.id));
    };

    const addFlashMessageOfType = (type: FlashTypes, duration: number, text: string) => {
        const id = nextFlashMessageId++;
        const flashMessage: FlashMessage = { id, type, duration, text };
        setMessages([...messages, flashMessage]);
    };

    const addSuccess = (text: string, duration: number = 5000) => {
        addFlashMessageOfType(FlashTypes.success, duration, text);
    };

    const addError = (text: string, duration: number = 5000) => {
        addFlashMessageOfType(FlashTypes.error, duration, text);
    };

    const addWarning = (text: string, duration: number = 5000) => {
        addFlashMessageOfType(FlashTypes.warning, duration, text);
    };

    return (
        <React.Fragment>
            <FlashComponent
                flash={{
                    messages,
                    actions: {
                        addSuccess,
                        addError,
                        addWarning,
                        removeFlashMessage,
                    },
                }}
            />
            <FlashActionsContext.Provider
                value={{
                    addSuccess,
                    addError,
                    addWarning,
                    removeFlashMessage,
                }}
            >
                {children}
            </FlashActionsContext.Provider>
        </React.Fragment>
    );
};

export { Provider, Consumer, FlashActionsContext as Context };
