import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "context";
import { Provider as PlanningProvider } from "context/Planning";
import Main from "components/Layout/Main";
import Loader from "components/App/Loader";
import LogoutOnExpiration from "components/App/LogoutOnExpiration";
import Protected from "components/Secured/Protected";

const Authentication = React.lazy(() => import("./Authentication"));
const Home = React.lazy(() => import("./Home"));
const Admin = React.lazy(() => import("./Admin"));
const Registration = React.lazy(() => import("./Registration"));
const Profile = React.lazy(() => import("./Profile"));
const Planning = React.lazy(() => import("./Planning"));
const Export = React.lazy(() => import("./Export"));
const Teacher = React.lazy(() => import("./Teacher"));

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    const renderAuth = () => {
        return (
            <PlanningProvider>
                <LogoutOnExpiration>
                    <Main>
                        <Routes>
                            <Route index element={<Home />} />
                            <Route path="/admin/*" element={<Protected capability="admin.*" element={<Admin />} />} />
                            <Route path="/profile/*" element={<Profile />} />
                            <Route path="/planning/*" element={<Planning />} />
                            <Route path="/export/*" element={<Export />} />
                            <Route path="/teacher/*" element={<Teacher />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </Main>
                </LogoutOnExpiration>
            </PlanningProvider>
        );
    };

    const renderNoAuth = () => {
        return (
            <Routes>
                <Route path="/registration/:rallye/:group/:gender/*" element={<Registration />} />
                <Route path="/*" element={<Authentication />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    };

    if (auth.isLoading) {
        return <Loader />;
    }

    if (auth.isLoggedIn) {
        return renderAuth();
    }

    return renderNoAuth();
};

export default ModuleRouter;
