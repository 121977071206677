import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo-rallye.svg";
import LogoGlobalis from "assets/images/logo-globalis.svg";
import ContactezNousPdf from "assets/documents/contactez_nous.pdf";
import MentionsLegales from "assets/documents/mentions_legales.pdf";

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <div className="footer__content-left">
                        {/*Lien vers home si connecté login si déconnecté*/}
                        <Link to="/" className="footer__logo">
                            <img src={Logo} alt="Rallye Clés en main" />
                            <span className="visuallyhidden">Vers la page d'accueil Rallye Clés en Main</span>
                        </Link>
                        <p className="text-uppercase footer__text">©2022 - Rallye clés en main</p>
                        <a
                            href={MentionsLegales}
                            target="_blank"
                            rel="noreferrer"
                            className="text-uppercase footer__text"
                        >
                            Mentions légales
                        </a>
                        <a
                            href={ContactezNousPdf}
                            target="_blank"
                            rel="noreferrer"
                            className="text-uppercase footer__text"
                        >
                            Contactez-nous
                        </a>
                    </div>
                    <div className="footer__content-left">
                        <p className="footer__text">Propulsé par</p>
                        <a
                            href="https://www.globalis-ms.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="footer__logo-globalis"
                        >
                            <img src={LogoGlobalis} alt="Globalis" />
                            <span className="visuallyhidden">Vers le site de Globalis</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
