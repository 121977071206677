import React, { PropsWithChildren } from "react";

import Topbar from "./Topbar";
import Footer from "./Footer";

const Layout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <React.Fragment>
            <Topbar />
            <main className="main">
                <div className="container">{children}</div>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
