import React, { useState, Dispatch, SetStateAction, useCallback, PropsWithChildren } from "react";
import usePromise from "helpers/usePromise";
import useQueryString from "helpers/useQueryString";
import staticCache from "helpers/cache/Static";

interface Pager {
    total: number;
    total_page: number;
    last_page: number;
    per_page: number;
    current_page: number;
    order?: string;
    direction?: string;
}

interface ListProviderInterface<T> {
    isLoading: boolean;
    hasError: boolean;
    error?: any;
    data: Array<T> & { pager: Pager } & { active_filters: HashMap<any> };
    refreshData: () => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
    changeFetcherParams: (params?: any) => any;
    selectedIds: Array<any>;
    setSelectedIds: (selectedIDs: Array<any>) => void;
    selectedId: any;
    setSelectedId: (selectedId: any) => void;
}

const ListContext = React.createContext<ListProviderInterface<any>>({
    isLoading: false,
    hasError: false,
    data: Object.assign([], {
        pager: { total: 0, total_page: 1, last_page: 1, per_page: 50, current_page: 1 },
        active_filters: {},
    }),
    refreshData: () => {},
    setLoading: () => {},
    changeFetcherParams: () => {},
    selectedIds: [],
    setSelectedIds: () => {},
    selectedId: undefined,
    setSelectedId: () => {},
});

interface Props<T> {
    dataFetcher: (params?: any) => Promise<Array<T> & { pager: Pager; active_filters: HashMap<any> }>;
    defaultFetcherParams?: any;
    defaultFilters?: any;
    cacheKey?: string;
}

const getCache = (cacheKey: string | undefined, propertyName: string, defaultValue?: any) => {
    if (cacheKey && staticCache.has(cacheKey)) {
        const cache = staticCache.get(cacheKey);
        return cache[propertyName] || defaultValue;
    }
    return defaultValue;
};

const setCache = (cacheKey: string | undefined, propertyName: string, value: any) => {
    if (cacheKey) {
        let merge = {};
        if (staticCache.has(cacheKey)) {
            merge = staticCache.get(cacheKey);
        }
        staticCache.set(cacheKey, Object.assign(merge, { [propertyName]: value }));
    }
};

export function ListProvider<T>({
    dataFetcher,
    cacheKey,
    children,
    defaultFetcherParams,
    defaultFilters,
}: PropsWithChildren<Props<T>>) {
    const queryString = useQueryString();
    const initialValues = Object.assign([], {
        pager: { total: 0, total_page: 1, last_page: 1, per_page: 50, current_page: 1 },
        active_filters: Object.assign({}, defaultFilters, queryString.all()),
    });

    const { isLoading, hasError, error, data, refreshData, changePromiseParams } = usePromise(
        dataFetcher,
        getCache(cacheKey, "fetcherParams", Object.assign({}, defaultFetcherParams, queryString.all())),
        initialValues
    );

    const [isLoadingState, setLoading] = useState(false);
    const [selectedIds, setInternalSelectedIds] = useState<Array<any>>(getCache(cacheKey, "selectedIds", []));
    const [selectedId, setSelectedId] = useState<any>(undefined);

    const setSelectedIds = useCallback(
        (selectedIds: Array<any>) => {
            setCache(cacheKey, "selectedIds", selectedIds);
            setInternalSelectedIds(selectedIds);
        },
        [setInternalSelectedIds, cacheKey]
    );

    const changeFetcherParams = useCallback(
        (params?: any) => {
            const mergeParams = Object.assign({}, params);
            setSelectedIds([]);
            setCache(cacheKey, "fetcherParams", mergeParams);
            changePromiseParams(mergeParams);
        },
        [changePromiseParams, setSelectedIds, cacheKey]
    );

    return (
        <ListContext.Provider
            value={{
                isLoading: isLoadingState || isLoading,
                hasError,
                error,
                data: data,
                refreshData,
                setLoading,
                changeFetcherParams,
                selectedIds,
                setSelectedIds,
                selectedId,
                setSelectedId,
            }}
        >
            {children}
        </ListContext.Provider>
    );
}

const Consumer = ListContext.Consumer;

export { Consumer, ListProvider as Provider, ListContext as Context };
