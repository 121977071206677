import Node from "./Node";

class Acl {
    rootNode: Node;

    constructor() {
        this.rootNode = new Node("root", true);
    }

    allow = (capability: string) => {
        this.rootNode.allow(capability.split("."));
    };

    isAllowed = (capability: string | string[]): boolean => {
        if (Array.isArray(capability)) {
            return capability.some((capability) => {
                return this.isAllowed(capability);
            });
        } else {
            return this.rootNode.isAllowed(capability.split("."));
        }
    };

    filterAllowed = (actions: HashMap<any>, acl: HashMap<string>): HashMap<any> => {
        const actionsAllowed: HashMap<any> = {};
        // eslint-disable-next-line
        for (const action in actions) {
            if (!acl[action]) {
                actionsAllowed[action] = actions[action];
            } else if (this.isAllowed(acl[action])) {
                actionsAllowed[action] = actions[action];
            }
        }
        return actionsAllowed;
    };
}

export default Acl;
