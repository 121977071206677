import React from "react";
import { NavLink } from "react-router-dom";

export interface ITopbarMenuItem {
    label: string;
    to: string;
}

interface Props extends ITopbarMenuItem {
    classPrefix?: string;
}

const TopbarMenuItem: React.FC<Props> = ({ label, to, classPrefix = "topbar-menu" }) => {
    return (
        <li className={`${classPrefix}__item`}>
            <NavLink className={`${classPrefix}__link`} to={to}>
                {label}
            </NavLink>
        </li>
    );
};

export default TopbarMenuItem;
