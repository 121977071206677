import React, { useContext } from "react";
import { IntlContext } from "context";
import { Loader as LoaderUI } from "ui/Loader";

const Loader: React.FC = () => {
    const intl = useContext(IntlContext);
    return (
        <div className="loader-backdrop-wrapper loader-backdrop-wrapper--full">
            <div className="loader-backdrop" />
            <LoaderUI message={intl.formatMessage("generic.loading")} />
        </div>
    );
};

export default Loader;
