import React, { useState, useCallback, useMemo, PropsWithChildren } from "react";
import RallyeDataFetcher from "dataProvider/Rallye";
import { RallyeInterface } from "dataProvider/Admin/Rallye";
import { PlanningInterface } from "dataProvider/Admin/Planning";

export interface PlanningProviderInterface {
    rallies?: RallyeInterface[];
    rallyeId?: string;
    setRallyeId: (id: string) => void;
    refreshRallyes: () => void;

    planning?: PlanningInterface;
    planningId?: string;
    plannings?: PlanningInterface[];
    setPlanning: (planning: PlanningInterface) => void;
    setPlanningId: (id: string) => void;
    setPlannings: (plannings: PlanningInterface[]) => void;

    schoolYear?: number;
    setSchoolYear: (year: number) => void;

    year?: string;
    setYear: (year: string) => void;

    category?: string;
    setCategory: (category: string) => void;

    groupId?: string;
    setGroupId: (id: string) => void;

    isLoading?: boolean;
    displayResults: boolean;
    setDisplayResults: (displayResults: boolean) => void;
}

const PlanningContext = React.createContext<PlanningProviderInterface>({
    setRallyeId: (id: string) => {},
    refreshRallyes: () => {},

    setPlannings: (planning: PlanningInterface[]) => {},
    setPlanning: (planning: PlanningInterface) => {},
    setPlanningId: (id: string) => {},

    setSchoolYear: (year: number) => {},

    setYear: (year: string) => {},

    setCategory: (category: string) => {},

    setGroupId: (id: string) => {},

    displayResults: false,
    setDisplayResults: (displayResults: boolean) => {},
});

const Consumer = PlanningContext.Consumer;

const Provider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [isInit, setIsInit] = useState<boolean>(false);
    const [rallies, setRallies] = useState<RallyeInterface[]>();
    const [plannings, setPlannings] = useState<PlanningInterface[]>();

    const [rallyeId, setRallyeId] = useState<string>();

    const [planning, setPlanning] = useState<PlanningInterface>();
    const [planningId, setPlanningId] = useState<string>();

    const currentSchoolYear = useMemo(() => {
        const year = new Date().getFullYear();
        if (new Date().getMonth() < 7) {
            return year - 1;
        }
        return year;
    }, []);

    const [schoolYear, setSchoolYear] = useState<number>(currentSchoolYear);

    const [year, setYear] = useState<string>();

    const [category, setCategory] = useState<string>();

    const [groupId, setGroupId] = useState<string>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayResults, setDisplayResults] = useState<boolean>(false);

    const refreshRallyes = useCallback(() => {
        setIsLoading(true);

        RallyeDataFetcher.fromFamily()
            .then((data) => {
                if (data.length) {
                    const options: RallyeInterface[] = [];
                    data.forEach((element) => {
                        if (!options.some((el) => el.id === element.id)) {
                            options.push(element);
                        }
                    });
                    if (!isInit) {
                        setRallyeId(data[0].id);
                        setGroupId(data[0].groupId);
                    }
                    setRallies(options);
                    setIsInit(true);
                }
            })
            .catch((err) => {
                // no-op
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isInit]);

    return (
        <PlanningContext.Provider
            value={{
                rallies,
                rallyeId,
                setRallyeId,
                refreshRallyes,

                planning,
                plannings,
                planningId,
                setPlannings,
                setPlanning,
                setPlanningId,

                schoolYear,
                setSchoolYear,

                year,
                setYear,

                category,
                setCategory,

                groupId,
                setGroupId,

                isLoading,
                displayResults,
                setDisplayResults,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};

export { Provider, Consumer, PlanningContext as Context };
