import React, { useContext } from "react";
import { AuthContext } from "context";
import { Navigate } from "react-router-dom";

interface Props {
    capability: string;
    element: React.ReactElement;
}

const Protected: React.FC<Props> = ({ capability, element }) => {
    const auth = useContext(AuthContext);

    if (auth.isAllowed(capability)) {
        return <React.Fragment>{element}</React.Fragment>;
    }

    return <Navigate to="/" />;
};

export default Protected;
